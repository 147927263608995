import 'tailwindcss/tailwind.css'
import 'react-toastify/dist/ReactToastify.css'
import TagManager from 'react-gtm-module/dist/TagManager'
import { useEffect } from 'react'
import OG_IMG from '@/images/og-image.png'
import Head from 'next/head'
import { useRouter } from 'next/router'

const App = ({ Component, pageProps }) => {
	const router = useRouter()

	useEffect(() => {
		TagManager.initialize({
			gtmId: 'GTM-NJ6HH8V',
			dataLayerName: 'PageDataLayer'
		})

		TagManager.dataLayer({
			event: 'VirtualPageView',
			pageTypeName: pageProps.page || null,
			url: router.pathname,
		})
	}, [])

	return (
		<>
			<Head>
				<meta property="og:url"                content="https://getvibel.com/" />
				<meta property="og:type"               content="website" />
				<meta property="og:title"              content="Vibel - Chat's Vibe Analysed" />
				<meta property="og:description"        content="Keeping on top of Chat's vibe. We track how chat feel about certain things through your stream" />
				<meta property="og:image"              content={OG_IMG.src} />
			</Head>
		<Component {...pageProps} />
		</>
	)
}


export default App
